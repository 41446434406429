import { Controller } from "stimulus"
import Choices from "choices.js/src/scripts/choices.js";
import "choices.js/src/styles/choices.scss";

export default class extends Controller {
    static targets = ["myoutput", "choicesinput"]

    connect() {
        // this.myoutputTarget.textContent = 'Hello from choices controller: ' + this.choicesinputTargets.length + ' targets found!'
        this.myoutputTarget.textContent = this.data.get("variable")

        // generic construction for all targets and using default options
        this.choicesinputTargets.forEach(this.autoConstructChoices)
    }

    autoConstructChoices(item, index) {
        var jsonStringArray = item.dataset.choicesarray // this should be a JSON object, need to parse and put into Array later
        var stringArray = item.dataset.itemsarray // this should be a string with different values separated by comma
        var choicesArray = new Array
        var itemsArray = new Array

        // now iterate thru options
        if (jsonStringArray != undefined && jsonStringArray != "") {
            var jsonData = JSON.parse(jsonStringArray)
            for (var i = 0; i < jsonData.length; i++) {
                choicesArray.push((jsonData[i])) // choicesarray needs to be an array of JSON objects
            }
        }

        // now iterate thru items
        if (stringArray != undefined && stringArray != "") {
            itemsArray = stringArray.split(",") // split comma delimited string to array
        }

        // with some default options
        const choices = new Choices(item, {
            silent: false,
            items: itemsArray,
            choices: choicesArray,
            renderChoiceLimit: -1,
            // maxItemCount: -1,
            maxItemCount: 3,
            addItems: true,
            addItemFilter: null,
            removeItems: true,
            // removeItemButton: false,
            removeItemButton: true,
            editItems: false,
            duplicateItemsAllowed: true,
            shouldSort: false,
        })

        // default options example
        /*
        const choices = new Choices(item, {
            silent: false,
            items: [],
            choices: [],
            renderChoiceLimit: -1,
            // maxItemCount: -1,
            maxItemCount: 3,
            addItems: true,
            addItemFilter: null,
            removeItems: true,
            // removeItemButton: false,
            removeItemButton: true,
            editItems: false,
            duplicateItemsAllowed: true,
            delimiter: ',',
            paste: true,
            searchEnabled: true,
            searchChoices: true,
            searchFloor: 1,
            searchResultLimit: 4,
            searchFields: ['label', 'value'],
            position: 'auto',
            resetScrollPosition: true,
            shouldSort: true,
            shouldSortItems: false,
            sorter: () => {},
            placeholder: true,
            placeholderValue: null,
            searchPlaceholderValue: null,
            prependValue: null,
            appendValue: null,
            renderSelectedChoices: 'auto',
            loadingText: 'Loading...',
            noResultsText: 'No results found',
            noChoicesText: 'No choices to choose from',
            itemSelectText: 'Press to select',
            addItemText: (value) => {
                return `Press Enter to add <b>"${value}"</b>`;
            },
            maxItemText: (maxItemCount) => {
                return `Only ${maxItemCount} values can be added`;
            },
            valueComparer: (value1, value2) => {
                return value1 === value2;
            },
            classNames: {
                containerOuter: 'choices',
                containerInner: 'choices__inner',
                input: 'choices__input',
                inputCloned: 'choices__input--cloned',
                list: 'choices__list',
                listItems: 'choices__list--multiple',
                listSingle: 'choices__list--single',
                listDropdown: 'choices__list--dropdown',
                item: 'choices__item',
                itemSelectable: 'choices__item--selectable',
                itemDisabled: 'choices__item--disabled',
                itemChoice: 'choices__item--choice',
                placeholder: 'choices__placeholder',
                group: 'choices__group',
                groupHeading: 'choices__heading',
                button: 'choices__button',
                activeState: 'is-active',
                focusState: 'is-focused',
                openState: 'is-open',
                disabledState: 'is-disabled',
                highlightedState: 'is-highlighted',
                selectedState: 'is-selected',
                flippedState: 'is-flipped',
                loadingState: 'is-loading',
                noResults: 'has-no-results',
                noChoices: 'has-no-choices'
            },
            // Choices uses the great Fuse library for searching. You
            // can find more options here: https://github.com/krisk/Fuse#options
            fuseOptions: {
                include: 'score'
            },
            callbackOnInit: null,
            callbackOnCreateTemplates: null
        })
        */
    }

}