import { Controller } from "stimulus"

import tinymce from 'tinymce/tinymce';
import 'tinymce/plugins/table';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/image';
import 'tinymce/plugins/imagetools';
import 'tinymce/plugins/media';
import 'tinymce/plugins/code';
// MUST declare the themes to pull into webpacker; use filemanager to navigate the node modules to see which files to import
// the chrome console also gives clues to what is missing and causing the webpacker load to fail
import 'tinymce/themes/silver/theme';
import 'tinymce/skins/ui/oxide/skin.min.css';

// import 'tinymce/skins/content/default/content.css';


export default class extends Controller {
    static targets = ["myoutput"]

    initialize() {
        this.tinymceremove();
        this.tinymcecontrol();
    }

    connect() {
        // debug message
        // this.myoutputTarget.textContent = 'TinyMCE loaded!';
    }

    disconnect() {}
    tinymceremove() {
        // alert('remove');
        tinymce.remove();
    }

    tinymcecontrol() {
        // alert('init');
        tinymce.init({
            // declare false for skin and content_css as workarounds
            skin: false,
            content_css: false,
            selector: 'textarea.tinymce',
            // some other settings, like height, language, // order of buttons on your toolbar etc.
            plugins: ['table', 'lists', 'image', 'imagetools', 'media', 'code'],
            // enable title field in the Image dialog
            image_title: true,
            // enable automatic uploads of images represented by blob or data URIs
            automatic_uploads: true,
            // URL of our upload handler (for more details check: https://www.tinymce.com/docs/configure/file-image-upload/#images_upload_url)
            // images_upload_url: 'postAcceptor.php',
            // here we add custom filepicker only to Image dialog
            file_picker_types: 'image',
            // and here's our custom image picker
            file_picker_callback: function(cb, value, meta) {
                var input = document.createElement('input');
                input.setAttribute('type', 'file');
                input.setAttribute('accept', 'image/*');

                // Note: In modern browsers input[type="file"] is functional without 
                // even adding it to the DOM, but that might not be the case in some older
                // or quirky browsers like IE, so you might want to add it to the DOM
                // just in case, and visually hide it. And do not forget do remove it
                // once you do not need it anymore.

                input.onchange = function() {
                    var file = this.files[0];

                    var reader = new FileReader();
                    reader.onload = function() {
                        // Note: Now we need to register the blob in TinyMCEs image blob
                        // registry. In the next release this part hopefully won't be
                        // necessary, as we are looking to handle it internally.
                        var id = 'blobid' + (new Date()).getTime();
                        var blobCache = tinymce.activeEditor.editorUpload.blobCache;
                        var base64 = reader.result.split(',')[1];
                        var blobInfo = blobCache.create(id, file, base64);
                        blobCache.add(blobInfo);

                        // call the callback and populate the Title field with the file name
                        cb(blobInfo.blobUri(), { title: file.name });
                    };
                    reader.readAsDataURL(file);
                };

                input.click();
            }
        });
    }
}