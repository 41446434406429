// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")
require("trix")
require("@rails/actiontext")
require("chartkick")
require("chart.js")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "bootstrap"
//import "bootswatch/dist/united/bootstrap.min.css" //another nice swatch (orange)
import "bootswatch/dist/pulse/bootstrap.min.css" //a nice swatch (purple)
// import "bootswatch/dist/lux/bootstrap.min.css" //black dominant swatch
import "../stylesheets/application"
import "@fortawesome/fontawesome-free/js/all"
import "controllers"
import "../stylesheets/my_bootstrap"

// import my chosenjs config
import "./chosenjs"

// flatpickr for datetime
import flatpickr from "flatpickr";
require("flatpickr/dist/flatpickr.css")

document.addEventListener("turbolinks:load", () => {
    flatpickr("[data-behavior='flatpickr']", {
      altInput: true,
      altFormat: "F j, Y",
      dateFormat: "Y-m-d",
    })
  })

global.toastr = require("toastr")
toastr.options = {
    "closeButton": true,
    "debug": false,
    "newestOnTop": false,
    "progressBar": true,
    "positionClass": "toast-top-full-width",
    "preventDuplicates": false,
    "onclick": null,
    "showDuration": "300",
    "hideDuration": "1000",
    "timeOut": "5000",
    "extendedTimeOut": "1000",
    "showEasing": "swing",
    "hideEasing": "linear",
    "showMethod": "fadeIn",
    "hideMethod": "fadeOut"
}


document.addEventListener("turbolinks:click", () => {
    $(".waitscreen").show();
})

document.addEventListener("turbolinks:load", () => {
    $('[data-toggle="tooltip"]').tooltip()
    $('[data-toggle="popover"]').popover()
    $(".waitscreen").hide()
})

window.googleMapsLoaded = () => {
    // alert("googleMaps loaded!")
}