import { Controller } from "stimulus"
import "easy-autocomplete"

export default class extends Controller {
    static targets = ["myoutput"]

    connect() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString)
        const searchterm = urlParams.get('q')

        if (searchterm != null && searchterm != "") {
            this.myoutputTarget.textContent = 'Last searched: ' + searchterm
        }

        document.addEventListener("turbolinks:load", function() {
            var $input = $("*[data-behavior='autocomplete']")

            var options = {
                url: function(phrase) {
                    return "/globalsearch.json?q=" + phrase;
                },
                getValue: "autocomplete",
                categories: [{
                        listLocation: "profiles",
                        header: "<strong>Profile</strong>",
                    },
                    {
                        listLocation: "competitions",
                        header: "<strong>Competition</strong>",
                    },
                    {
                        listLocation: "places",
                        header: "<strong>Places</strong>",
                    },
                    {
                        listLocation: "posts",
                        header: "<strong>Posts</strong>",
                    },
                    {
                        listLocation: "users",
                        header: "<strong>Users</strong>",
                    }
                ],
                list: {
                    onChooseEvent: function() {
                        var url = $input.getSelectedItemData().url
                        $input.val("")
                        Turbolinks.visit(url)
                    }
                },
                adjustWidth: false,
                theme: "plate-dark",
                requestDelay: 500

                // test with dummy data
                // data: ["John", "Paul", "George", "Ringo"]
            }

            if ($input != null) {
                $input.easyAutocomplete(options)
            }
        });
    }
}