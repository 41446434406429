import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ["myoutput"]

    initialize() {
        // for this form, prevent enter key from auto submitting
        $(document).on('turbolinks:load', () => {
            $('form').on('keypress', e => {
                if (e.keyCode == 13) {
                    return false;
                }
            });
        });
    }

    connect() {
        // debug message
        // this.myoutputTarget.textContent = 'Display map for: ' + this.data.get("longitude") + "," + this.data.get("latitude") + " " + this.data.get("funcname")

        switch (this.data.get("funcname")) {
            case "initMap":
                this.initMap(this.data.get("latitude"), this.data.get("longitude"))
                break

            case "initMap2":
                this.initMap2()
                break
            default:
                alert("No function on connect")
                break
        }
    }

    initMap(lat, lng) {
        var myCoords = new google.maps.LatLng(lat, lng)
        var mapOptions = {
            center: myCoords,
            zoom: 14
        }
        this.map = new google.maps.Map(document.getElementById('map'), mapOptions)
        this.marker = new google.maps.Marker({
                position: myCoords,
                map: this.map
            })
            // alert("initMap")
    }

    initMap2() {
        var lat = document.getElementById('place_latitude').value
        var lng = document.getElementById('place_longitude').value

        // if not defined create default position
        if (!lat || !lng) {
            lat = 51.5
            lng = -0.125
            document.getElementById('place_latitude').value = lat
            document.getElementById('place_longitude').value = lng
        }

        var myCoords = new google.maps.LatLng(lat, lng)
        var mapOptions = {
            center: myCoords,
            zoom: 14
        }
        this.map = new google.maps.Map(document.getElementById('map2'), mapOptions)
        this.marker = new google.maps.Marker({
            position: myCoords,
            animation: google.maps.Animation.DROP,
            map: this.map,
            draggable: true
        })

        // add autocomplete
        this.autocomplete = new google.maps.places.Autocomplete(document.getElementById('place_name'))
        this.autocomplete.bindTo('bounds', this.map)
        this.autocomplete.setFields(['address_components', 'geometry', 'icon', 'name'])
        this.autocomplete.addListener('place_changed', this.placeChanged.bind(this))

        // refresh marker position and recenter map on marker
        function refreshMarker() {
            var lat = document.getElementById('place_latitude').value
            var lng = document.getElementById('place_longitude').value
            var myCoords = new google.maps.LatLng(lat, lng)
            this.marker.setPosition(myCoords)
            this.map.setCenter(this.marker.getPosition())
        }
        // when input values change call refreshMarker
        document.getElementById('place_latitude').onchange = refreshMarker
        document.getElementById('place_longitude').onchange = refreshMarker
            // when marker is dragged update input values


        google.maps.event.addListener(this.marker, 'drag', function() {
            // console.log('Dragging...')
            // console.log(this.getPosition().lat())
            // console.log(this.getPosition().lng())
            var newlat = (Math.round(this.getPosition().lat() * 1000000)) / 1000000
            var newlng = (Math.round(this.getPosition().lng() * 1000000)) / 1000000
            document.getElementById('place_latitude').value = newlat
            document.getElementById('place_longitude').value = newlng
        })

        google.maps.event.addListener(this.marker, 'dragend', function() {
            // console.log('Drag end...')
            // console.log(this.getPosition().lat())
            // console.log(this.getPosition().lng())
            var myCoords = new google.maps.LatLng(this.getPosition().lat(), this.getPosition().lng())
            this.map.panTo(myCoords)
        })

        // alert("initMap2")
    }

    placeChanged() {
        let place = this.autocomplete.getPlace()

        if (!place.geometry) {
            // window.alert(`No details available for input: ${place.name}`)
            return
        }

        if (place.geometry.viewport) {
            this.map.fitBounds(place.geometry.viewport)
        } else {
            this.map.setCenter(place.geometry.location)
            this.map.setZoom(17)
        }

        this.marker.setPosition(place.geometry.location)
        this.marker.setVisible(true)

        document.getElementById('place_latitude').value = place.geometry.location.lat()
        document.getElementById('place_longitude').value = place.geometry.location.lng()
    }

}