// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ["myoutput"]

    connect() {
        // console.log("hello from stimulusjs")
        this.myoutputTarget.textContent = 'Hello, ' + this.data.get("varone") + " " + this.data.get("vartwo") + ', from Stimulus!'
    }
}